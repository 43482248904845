
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import selectedClient from "@/views/marketing/components/customer-filter.vue";
import { Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import noticeChannel from "../components/notice-channel.vue";
import t from "@common/src/i18n/t";
const base = namespace("base");
const coupon = namespace("coupon");
const marketing = namespace("marketing");
const app = namespace("app");
@Component({
  components: {
    selectedClient,
    noticeChannel
  }
})
@Table("loadListData", { pageSize: 5 })
export default class SendCoupon extends Vue {
  @base.Action getDictionaryList;
  @coupon.Mutation setDialog;
  @coupon.Action getDetail;
  @marketing.Action queryRemindRecordPage; //查询保养
  @coupon.Action distributeCoupons;
  @base.Action queryStoreMessageBalance;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @app.State btnLoading;
  emptyToLine = emptyToLine;
  //计算属性
  get breadData() {
    return [
      { name: t("marketing.discount-coupon"), path: "/marketing/discount-coupon" },
      { name: t("v210831.issue-coupons") }
    ];
  }
  notifyMethods = [];
  tableData = [];
  loading = false;
  dialogVisible = true;
  currentPage4 = 4;
  wechatChecked = false;
  messageChecked = false;
  get miniProgram() {
    let newMini = this.tableData.filter(item => {
      return item.weixinMiniProgramFlag === 1;
    });
    return newMini.length;
  }
  searchForm = {
    cardTemplateName: "",
    cardType: ""
  };
  conponDetail = {
    activityName: undefined,
    remainingQuantity: undefined
  };
  created() {
    this.init();
  }
  mounted() {}
  init() {
    this.getDetail({ activityCode: this.$route.params.activityCode }).then(res => {
      this.conponDetail = res.data;
    });
  }
  remove(row) {
    this.loading = true;
    this.tableData = this.tableData.filter(item => {
      return item.customerCode !== row.customerCode;
    });
    this.loading = false;
  }
  sendCoupon() {
    if (!this.tableData.length) {
      this.$message.error(t("v210831.please-select-a-client-first"));
      return;
    }
    if (this.conponDetail.remainingQuantity < this.tableData.length) {
      this.$message.error(t("v210831.insufficient-coupons-remaining"));
      return;
    }
    if (this.notifyMethods.includes("sms")) {
      // 如果选择了短信
      this.queryStoreMessageBalance().then(data => {
        //  data.data.smsBalance
        console.log("[  data.data.smsBalance ]", Number(data.data.smsBalance) < this.tableData.length);
        if (Number(data.data.smsBalance) < this.tableData.length) {
          this.$message.warning(t("v210831.insufficient-sms-balance"));
          return;
        } else {
          this.passRight();
        }
      });
    } else {
      this.passRight();
    }
  }
  passRight() {
    // 点击发放
    this.btnStartLoading();
    this.distributeCoupons({
      activityCode: this.$route.params.activityCode,
      customerList: this.tableData,
      notifyMethods: this.notifyMethods
    })
      .then(res => {
        this.$message.success(t("v210831.coupon-issued-successfully"));
        this.$router.push("/marketing/discount-coupon");
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  clickTarget() {
    this.setDialog(true);
  }
  getList(arr) {
    this.loading = true;
    let newArr = this.tableData.concat(arr);
    var result = [];
    var obj = {};
    for (var i = 0; i < newArr.length; i++) {
      if (!obj[newArr[i].customerCode]) {
        result.push(newArr[i]);
        obj[newArr[i].customerCode] = true;
      }
    }
    this.tableData = result;
    this.loading = false;
  }

  // ////
}
